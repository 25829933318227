<template>
  <div>
      <el-card class="box-card">
          <div slot="header" class="clearfix">
   <el-page-header @back="goBack" content="种子销售管理">
</el-page-header>
</div>
 <el-tabs v-model="activeName" @tab-click="handleClick">
     <!-- -------------------------------基本信息模块开始------------------------------------------------ -->
    <el-tab-pane label="基本信息" name="first">
        <el-card class="box-card">
      <!-- 搜索与添加区域 -->
      <el-row :gutter="10">
        <!-- <el-col :span="6">
          <el-input
            clearable
            v-model="queryInfo.query"
            @clear="getDataList"
            placeholder="请输入品种名称"
            class="input-with-select"
          >
            <el-button
              @click="getDataList"
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </el-col> -->
        <el-col :span="2">
          <el-button type="primary" @click="addDialogVisible = true"
            >添加基本信息</el-button
          >
        </el-col>
      </el-row>
       <!-- 数据列表区域 -->
      <el-table v-loading="loading" :data="tableData" @row-dblclick='edit' style="width: 100%">
        <el-table-column label="编号" type="index"></el-table-column>
        <el-table-column label="经营单位" prop="j_qygk"></el-table-column>
        <el-table-column label="资质证件" prop="j_zzzj"></el-table-column>
        <el-table-column label="分支机构" prop="j_fzjg"></el-table-column>
        <el-table-column label="负责人" prop="j_fzr"></el-table-column>
        
        
        <el-table-column label="操作">
      
          <template slot-scope="scope">
        
            <el-tooltip effect="dark" content="删除" placement="top-end">
              <el-button
                type="warning"
                @click.stop="delInfo(scope.row.id)"
                icon="el-icon-delete"
              >删除</el-button>
            </el-tooltip>
           
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        background
        layout="prev, pager, next,total"
        :total="total"
        :page-size="queryInfo.pageSize"
        @current-change ="handleCurrentChange"
      >
      </el-pagination>
      <el-drawer
        title="添加品种信息"
        :visible.sync="addDialogVisible"
        width="40%"
        
        @closed="closedialog">
        <!-- 表单区 -->
        <el-form size="medium"  ref="addFormRef" :model="addFormInfo" label-width="120px">
         
          <el-form-item label="经营单位"  prop="j_qygk">
            <el-input v-model="addFormInfo.j_qygk" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="资质证件"  prop="j_zzzj">
            <el-input v-model="addFormInfo.j_zzzj" placeholder=""></el-input>
          </el-form-item>
          
          
          <el-form-item label="分支机构"  prop="j_fzjg">
            <el-input v-model="addFormInfo.j_fzjg" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="负责人"  prop="j_fzr">
            <el-input v-model="addFormInfo.j_fzr" placeholder="例如：玉米"></el-input>
          </el-form-item>
        </el-form>
          <div  class="drawer-footer">
          
          <el-button type="primary" @click="addFormSubmit">确 定</el-button>
          <el-button type="danger" @click="addDialogVisible = false">取 消</el-button>
          </div>
      </el-drawer>
        </el-card>
    </el-tab-pane>
    <!-- --------------------基本信息模块结束 -->
    <el-tab-pane label="营销信息" name="second"><yxmanage :xs_id="queryInfo.id" :key="second"></yxmanage></el-tab-pane>
    
    
  </el-tabs>
</el-card>
    
  </div>
</template>

<script>
import yxmanage from './yxmanage.vue'
export default {
    components: {
      yxmanage: yxmanage
    },
    data(){
        return{
          visible:false,
          loading:true,
          second:"",
            activeName: 'first',
            queryInfo: {
        token: "",
        query: "",
        id:"",
        page: 1,
        pageSize: 8
      },
      total: 0,
      // 列表渲染数据
      tableData: [],
      addDialogVisible: false,
      addFormInfo:{
          id:'',
          token:'',
          j_qygk:'',
          j_zzzj:'',
          j_fzjg:'',
          j_fzr:'',
        },
        }
    },
    mounted(){
        console.log(this.$route.query.id)
        this.queryInfo.id=this.$route.query.id
        console.log(this.queryInfo.id)
        this.getDataList();
    },
    created(){
         this.tokenStr = window.sessionStorage.getItem("token");
         
    },
    methods:{
      closedialog(){ 
      
        for(var name in this.addFormInfo) {
    this.addFormInfo[name]=""
}},
      async delInfo(id){
          const { data: res } = await this.$http.post(
        "delXSJBXX",
        {id:id}
      );
      if(res.code==0){
          this.getDataList();
          this.$message({
            showClose:true,
            message:'删除成功',
            type:'success'
          })
      }else{
        this.$message({
            showClose:true,
            message:'删除失败',
            type:'error'
          })
      }
      },
        edit(row,event,column){
      console.log(row)
      let dataRow = JSON.parse(JSON.stringify(row));
      this.addFormInfo= dataRow;
      console.log(this.addFormInfo)
      this.addDialogVisible=true;
    },
        goBack(){
            this.$router.push("/company/pinzhong")
        },
        handleClick(tab){
            if(tab.name=="second"){
              this.second=new Date().getTime()
            }
        },
         async getDataList() {
      this.queryInfo.token = this.tokenStr;
      try {
        const { data: res } = await this.$http.post(
        "getXSJBXX",
        this.queryInfo
      );
      this.loading=false;
      this.tableData = res.data.row;
      this.total = res.data.total;
      } catch (error) {

        this.loading=false
      }
     
  
    },
    addFormSubmit(){
      this.addFormInfo.token = this.tokenStr;
      this.addFormInfo.j_xs_id=this.queryInfo.id;
      this.$http.post(
        'addXSJBXX',
        this.addFormInfo
        ).then((res)=>{
          console.log(res)
          if(res.data.code == 0){
            this.$message.success("添加信息成功")
          this.getDataList()
        }
        })
      this.addDialogVisible= false;
      this.getDataList();
    },
    handleCurrentChange(newPage) {
      // console.log("当前页是" + newPage);
      this.queryInfo.page = newPage
      this.getDataList();
    },
     
    }
}
</script>

<style>
.drawer-footer{
  text-align: center;
  width: 100%;
}

</style>