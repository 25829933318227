<template>
  <div>
    <el-card class="box-card">
      <!-- 搜索与添加区域 -->
      <el-row :gutter="10">
        <!-- <el-col :span="6">
          <el-input
            clearable
            v-model="queryInfo.query"
            @clear="getDataList"
            placeholder="请输入品种名称"
            class="input-with-select"
          >
            <el-button
              @click="getDataList"
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </el-col> -->
        <el-col :span="2">
          <el-button type="primary" @click="addDialogVisible = true"
            >添加营销信息</el-button
          >
        </el-col>
      </el-row>
      <!-- 数据列表区域 -->
      <el-table :data="tableData" @row-dblclick="edit" style="width: 100%">
        <el-table-column label="编号" type="index"></el-table-column>
        <el-table-column label="经营单位名称" prop="j_jydwmc"></el-table-column>
        <el-table-column label="种业客户">
          <template slot-scope="scope"
            ><el-tag effect="dark">{{ scope.row.j_zykh }}</el-tag
            >：{{ scope.row.j_yzdw }}</template
          >
        </el-table-column>
        <el-table-column label="种子采购" prop="j_zzcg"></el-table-column>
        <el-table-column label="种子出入库" prop="j_zzrk"></el-table-column>
        <el-table-column label="种子销售" prop="j_zzxs"></el-table-column>
        <el-table-column label="库存" prop="j_huizong"></el-table-column>

        <!-- <el-table-column label="品种简介" prop="content"></el-table-column> -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- <el-tooltip effect="dark" content="管理" placement="top-start">
              <el-button
                @click="getInfo(scope.row)"
                type="primary"
                icon="el-icon-s-tools"
              ></el-button>
            </el-tooltip> -->
            <el-tooltip effect="dark" content="删除" placement="top-end">
              <el-button
                type="warning"
                @click.stop="delInfo(scope.row.id)"
                icon="el-icon-delete"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        background
        layout="prev, pager, next,total"
        :total="total"
        :page-size="queryInfo.pageSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <el-drawer
        title="添加品种信息"
        :visible.sync="addDialogVisible"
        width="40%"
        @closed="closedialog"
      >
        <!-- 表单区 -->
        <el-form
          size="medium"
          ref="addFormRef"
          :model="addFormInfo"
          label-width="120px"
        >
          <!-- form -->
          <el-form-item label="经营单位名称" prop="j_jydwmc">
            <el-input v-model="addFormInfo.j_jydwmc" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="种业客户" prop="j_zykh">
            <el-col :span="5">
              <el-select v-model="addFormInfo.j_zykh" placeholder="请选择">
                <el-option label="个人" value="个人"></el-option>
                <el-option label="单位" value="单位"></el-option>
              </el-select>
            </el-col>
            <el-col :span="18">
              <el-input v-model="addFormInfo.j_yzdw" placeholder=""></el-input>
            </el-col>
          </el-form-item>

          <el-form-item label="种子采购" prop="j_zzcg">
            <el-input v-model="addFormInfo.j_zzcg" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="种子出/入库" prop="j_zzrk">
            <el-input v-model="addFormInfo.j_zzrk" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="种子销售" prop="j_zzxs">
            <el-input v-model="addFormInfo.j_zzxs" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="种子库存" prop="j_huizong">
            <el-input v-model="addFormInfo.j_huizong"></el-input>
          </el-form-item>
          <div class="drawer-footer">
            <el-button type="primary" @click="addFormSubmit">确 定</el-button>
            <el-button type="danger" @click="addDialogVisible = false"
              >取 消</el-button
            >
          </div>
        </el-form>
      </el-drawer>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "yxmanage",
  props: {
    xs_id: {
      type: String,
    },
  },
  data() {
    return {
      queryInfo: {
        query: "",
        id: "",
        page: 1,
        pageSize: 8,
      },
      total: 0,
      // 列表渲染数据
      tableData: [],
      addDialogVisible: false,
      addFormInfo: {
        id: "",
        token: "",
        j_jydwmc: "",
        j_zykh: "",
        j_yzdw: "",
        j_zzcg: "",
        j_zzrk: "",
        j_zzxs: "",
        j_huizong: "",
      },
    };
  },
  created() {
    this.queryInfo.id = this.xs_id;
    this.getDataList();
  },
  methods: {
    closedialog() {
      for (var name in this.addFormInfo) {
        this.addFormInfo[name] = "";
      }
    },
    async delInfo(id) {
      const { data: res } = await this.$http.post("delXSYXXX", { id: id });
      if (res.code == 0) {
        this.getDataList();
        this.$message({
          showClose: true,
          message: "删除成功",
          type: "success",
        });
      } else {
        this.$message({
          showClose: true,
          message: "删除失败",
          type: "error",
        });
      }
    },
    edit(row, event, column) {
      console.log(row);
      let dataRow = JSON.parse(JSON.stringify(row));
      this.addFormInfo = dataRow;
      console.log(this.addFormInfo);
      this.addDialogVisible = true;
    },
    async getDataList() {
      // this.queryInfo.token = this.tokenStr;
      const { data: res } = await this.$http.post("getXSyx", this.queryInfo);
      this.tableData = res.data.row;
      this.total = res.data.total;
      console.log(res.data);
    },
    addFormSubmit() {
      this.addFormInfo.token = this.tokenStr;
      this.addFormInfo.j_xs_id = this.queryInfo.id;
      this.$http.post("addXSYXXX", this.addFormInfo).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.$message.success("添加信息成功");
          this.getDataList();
        }
      });
      this.addDialogVisible = false;
      this.getDataList();
    },
    handleCurrentChange(newPage) {
      // console.log("当前页是" + newPage);
      this.queryInfo.page = newPage;
      this.getDataList();
    },
  },
};
</script>

<style>
.drawer-footer {
  text-align: center;
  width: 100%;
}
</style>