import { render, staticRenderFns } from "./yxmanage.vue?vue&type=template&id=4f562e8a&"
import script from "./yxmanage.vue?vue&type=script&lang=js&"
export * from "./yxmanage.vue?vue&type=script&lang=js&"
import style0 from "./yxmanage.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports